import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { absoluteFill } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { DatoGatsbyImage } from '../DatoGatsbyImage/DatoGatsbyImage'

interface Props extends ComponentPropsWithoutRef<'figure'> {
  data?: Queries.QuoteFragment | null
  layout: 'INTRO'
}

export const Quote = ({
  data,
  // layout,
  ...props
}: Props): JSX.Element => {
  const styles = {
    figure: css`
      margin: 0;
      padding-left: var(--gtr-36);
      border-left: 2px solid ${colors.gray85};
    `,
    blockquote: css`
      margin: 0;
      display: flex;
      font-size: var(--fs-54);
      font-family: var(--ff-display);
      line-height: 1.125;
      color: ${colors.red};
      min-width: 12ch;
      &:before {
        content: '“';
        display: block;
      }
      div:after {
        content: '”';
        display: inline-block;
        width: 0px;
        overflow: visible;
      }
    `,
    figcaption: css`
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 1em;
      color: ${colors.red};
      margin-top: 2em;
      ${!data?.attributionHeadshot?.gatsbyImageData &&
      css`
        grid-template-columns: calc(var(--fs-54) * 0.15) 1fr;
        margin-top: 1.5em;
      `}
    `,
    image: css`
      grid-row: 1 / 3;
      width: 5em;
      height: 5em;
      position: relative;
      [data-gatsby-image-wrapper] img {
        border-radius: 50%;
      }
      &:after {
        content: '';
        ${absoluteFill}
        border-radius: 50%;
        border: 1px solid #00000011;
        box-sizing: border-box;
      }
    `,
    name: css`
      grid-column: 2 / 3;
      align-self: flex-end;
      font-size: var(--fs-21);
      font-style: normal;
      font-weight: 500;
    `,
    title: css`
      grid-column: 2 / 3;
    `,
  }
  return (
    <figure
      css={styles.figure}
      {...props}
    >
      <blockquote css={styles.blockquote}>
        <div>{data?.quote}</div>
      </blockquote>
      <figcaption css={styles.figcaption}>
        {data?.attributionHeadshot?.gatsbyImageData && (
          <div css={styles.image}>
            <DatoGatsbyImage
              image={data?.attributionHeadshot?.gatsbyImageData}
              alt={
                data?.attributionHeadshot?.alt ||
                data?.attributionName ||
                ''
              }
            />
          </div>
        )}
        <cite css={styles.name}>{data?.attributionName}</cite>
        {data?.attributionTitle && (
          <span css={styles.title}>{data?.attributionTitle}</span>
        )}
      </figcaption>
    </figure>
  )
}

export const QuoteFragment = graphql`
  fragment Quote on DatoCmsQuote {
    __typename
    id: originalId
    quote
    attributionName
    attributionTitle
    attributionHeadshot {
      gatsbyImageData(
        imgixParams: {
          fit: "crop"
          crop: "focalpoint"
          q: 50
          sat: -100
          con: 10
          ar: "1:1"
        }
        width: 180
      )
      alt
    }
  }
`
